import React from "react"
import Modal from "react-modal"
import { DeclineButton, Button } from "../components/buttons"

import simpleImage from "../images/arctium-app-03.png"

import Layout from "../components/layout"
import SEO from "../components/seo"

Modal.setAppElement(`#___gatsby`)

const App = () => {
  const customStyles = {
    content: {
      position: "relative",
      top: "auto",
      left: "auto",
      right: "auto",
      bottom: "auto",
      maxWidth: "960px",
      margin: "32px auto",
      padding: 0,
      border: 0,

      background: "var(--pure)",
      color: "var(--pureInverted)",
      borderRadius: "0.75rem",
      boxShadow:
        "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    },
  }
  const [modalOpen, setModalOpen] = React.useState(false)
  const [modalOpen2, setModalOpen2] = React.useState(false) 
  const modalCloseTimeout = 300
  function openModal() {
    setModalOpen(true)
  }
  const closeModal = () => {
    setModalOpen(false)
    // setTimeout(() => React.navigate(`/`), modalCloseTimeout)
  }
  const closeModal2 = () => {
    setModalOpen2(false)
    // setTimeout(() => React.navigate(`/`), modalCloseTimeout)
  }
  return (
    <Layout>
      <SEO
        title="This is the Arctium App"
        description="The Arctium App is an App that aims to unify the Arctium User Experience in one easy to use Application. Use it to download Arctium Software and more."
      />
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        contentLabel="Modal"
        closeTimeoutMS={modalCloseTimeout}
        style={customStyles}
      >
        <div className="modal-content px-3 py-5 font-sans">
          {" "}
          <div className="bg-blue-700 px-4 py-2 shadow-md text-white rounded-lg mb-8 md:text-4xl lg:py-2 lg:px-4 leading-none">
            <p className="font-thin text-base my-1 leading-normal">
              Thanks for your interest!
              <br />
              Before you continue please take your time to read our EULA.
              <br />
              If you agree you can continue the download. Otherwise we can't
              provide you access to our services.
            </p>
          </div>
          <div class="tosContainer">
            <div class="tosContent">
              <h2>
                End-User License Agreement (EULA) of {""}
                <span class="app_name">Arctium</span>
              </h2>
              <b>
                <p>LAST REVISED October 5, 2019</p>
                <p>
                  THIS VERSION OF ARCTIUM END USER LICENSE AGREEMENT WILL BECOME
                  EFFECTIVE ON October 5, 2019.
                </p>
                <p>
                  YOU SHOULD CAREFULLY READ THIS AGREEMENT ("EULA") BEFORE
                  INSTALLING OR USING ARCTIUM PRODUCTS AND SERVICES. IF YOU DO
                  NOT AGREE WITH ALL OF THE TERMS OF THIS AGREEMENT, YOU MAY NOT
                  INSTALL OR OTHERWISE ACCESS THE PLATFORM.
                </p>
              </b>
              <p>
                This End-User License Agreement ("EULA") is a legal agreement
                between you and {""}
                <span class="company_name">Arctium</span>
              </p>
              <p>
                This EULA agreement governs your acquisition and use of our {""}
                <span class="app_name">Arctium</span> Products and Services
                ("Platform") directly from {""}
                <span class="company_name">Arctium</span> or indirectly through{" "}
                a {""}
                <span class="company_name">Arctium</span> authorized reseller or
                distributor (a "Reseller").
              </p>
              <p>
                Please read this EULA agreement carefully before completing the
                installation process and using the {""}
                <span class="app_name">Arctium</span> Platform. It provides a
                license to use the {""}
                <span class="app_name">Arctium</span> Platform and contains
                warranty information and liability disclaimers.
              </p>
              <p>
                If you register for a free trial of the {""}
                <span class="app_name">Arctium</span> Platform, this EULA
                agreement will also govern that trial. By clicking "accept" or
                installing and/or using the {""}
                <span class="app_name">Arctium</span> Platform, you are
                confirming your acceptance of the Platform and agreeing to
                become bound by the terms of this EULA agreement.
              </p>
              <p>
                If you are entering into this EULA agreement on behalf of a
                company or other legal entity, you represent that you have the
                authority to bind such entity and its affiliates to these terms
                and conditions. If you do not have such authority or if you do
                not agree with the terms and conditions of this EULA agreement,
                do not install or use the Platform, and you must not accept this
                EULA agreement.
              </p>
              <p>
                This EULA agreement shall apply only to the Platform supplied by{" "}
                {""}
                <span class="company_name">Arctium</span> herewith regardless of
                whether other Platform is referred to or described herein. The
                terms also apply to any {""}
                <span class="company_name">Arctium</span> updates, supplements,
                Internet-based services, and support services for the Platform,
                unless other terms accompany those items on delivery. If so,
                those terms apply.
              </p>
              <h3>License Grant</h3>
              <p>
                <span class="company_name">Arctium</span> hereby grants you a
                personal, non-transferable, non-exclusive licence to use the{" "}
                {""}
                <span class="app_name">Arctium</span> Platform on your devices
                in accordance with the terms of this EULA agreement.
              </p>
              <p>
                You are permitted to load the {""}
                <span class="app_name">Arctium</span> Platform (for example a
                PC, laptop, mobile or tablet) under your control. You are
                responsible for ensuring your device meets the minimum
                requirements of the {""}
                <span class="app_name">Arctium</span> Platform.
              </p>
              <p>You are not permitted to:</p>
              <ul>
                <li>
                  Edit, alter, modify, adapt, translate or otherwise change the
                  whole or any part of the Platform nor permit the whole or any
                  part of the Platform to be combined with or become
                  incorporated in any other Platform, nor decompile, disassemble
                  or reverse engineer the Platform or attempt to do any such
                  things
                </li>
                <li>
                  Reproduce, copy, distribute, resell or otherwise use the
                  Platform for any purpose
                </li>
                <li>
                  Allow any third party to use the Platform on behalf of or for
                  the benefit of any third party
                </li>
                <li>
                  Use the Platform in any way which breaches any applicable
                  local, national or international law
                </li>
                <li>
                  Use the Platform for any purpose that {""}
                  <span class="company_name">Arctium</span> considers is a
                  breach of this EULA agreement
                </li>
              </ul>
              <h3>Anti-Cheating &amp; Anti-Piracy</h3>
              <p>
                In an effort to combat the efforts of those individuals who are
                willing to violate the EULA, Arctium utilizes an 'anti-cheating'
                utility that runs as part of Arctium products and services. This
                'anti-cheating' utility performs limited scans of:
              </p>
              <ul>
                <li>
                  the Random Access Memory ('RAM') that is occupied by a Arctium
                  product to confirm that the Arctium products program has not
                  been altered or 'hacked' in violation of the EULA;
                </li>
                <li>
                  the Arctium products 'process' to determine if any
                  unauthorized third-party programs or computer code has been
                  attached to the Arctium products process;
                </li>
                <li>
                  the Windows Process List to determine if any confirmed
                  hacking, botting or cheating programs are presently open in
                  violation of the EULA
                </li>
                <li>
                  the Windows Handles list to see which processes have a handle
                  to the Arctium products 'process'. Additional information
                  obtained from RAM and/or disc for the processes that have a
                  handle to the Arctium products 'process' will be used to
                  determine if it is a confirmed hacking, botting or cheating
                  program in violation of the EULA.
                </li>{" "}
              </ul>
              <p>
                The information that is obtained from this activity is
                communicated to Arctium, who uses it solely to determine if the
                computer that a user is using to log on or to use Arctium
                services and prodcuts has any unauthorized third-party programs,
                such as 'hacks', 'bots', or 'cheats' that violate the EULA and
                is not used for any marketing purposes, and will never be sold,
                leased, or otherwise transferred to any third party (except as
                required for judicial action).
              </p>
              <p>
                In the event that Arctium detects an unauthorized third-party
                program, Arctium may in its sole determination:
              </p>
              <ul>
                <li>
                  communicate information back to Arctium, including without
                  limitation your Arctium services account name, details about
                  the unauthorized third party program(s) detected, and the time
                  and date the unauthorized third party program was detected;
                  and/or
                </li>
                <li>
                  exercise any or all of its rights under the EULA, with or
                  without prior notice to you.
                </li>
              </ul>
              <h3>Intellectual Property and Ownership</h3>
              <p>
                <span class="company_name">Arctium</span> shall at all times
                retain ownership of the Platform as originally downloaded by you
                and all subsequent downloads of the Platform by you. The
                Platform (and the copyright, and other intellectual property
                rights of whatever nature in the Platform, including any
                modifications made thereto) are and shall remain the property of{" "}
                {""}
                <span class="company_name">Arctium</span>.
              </p>
              <p>
                <span class="company_name">Arctium</span> reserves the right to
                grant licences to use the Platform to third parties.
              </p>
              <h3>Termination</h3>
              <p>
                This EULA agreement is effective from the date you first use the
                Platform and shall continue until terminated. You may terminate
                it at any time upon written notice to {""}
                <span class="company_name">Arctium</span>.
              </p>{" "}
              <p>
                It will also terminate immediately if you fail to comply with
                any term of this EULA agreement. Upon such termination, the
                licenses granted by this EULA agreement will immediately
                terminate and you agree to stop all access and use of the
                Platform. The provisions that by their nature continue and
                survive will survive any termination of this EULA agreement.
              </p>
              <h3>Disclaimer</h3>
              <p>
                This Platform is provided "AS IS" without warranty of any kind
                including, but not limited to, warranties of merchantability,
                fitness for a particular purpose and non-infringement. In no
                event will Arctium be liable for any direct, indirect,
                incidental, special, exemplary or consequential damages,
                including damages for loss of profits, loss or inaccuracy of
                data, incurred by any person from such person's usage of this
                Platform even if advised of the possibility of such damages.{" "}
              </p>
              <h3>Governing Law</h3>
              <p>
                This EULA agreement, and any dispute arising out of or in
                connection with this EULA agreement, shall be governed by and
                construed in accordance with the laws of {""}
                <span class="country">Germany</span>.
              </p>
            </div>
          </div>
          <div class="modalFooter">
            <DeclineButton
              onClick={closeModal}
              class="button buttonDecline"
              id="downloadButton"
            >
              Decline
            </DeclineButton>
            <a href="https://arctium.app/install">
              <Button class="button" id="acceptButton">
                Accept &amp; Download
              </Button>
            </a>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modalOpen2}
        onRequestClose={closeModal2}
        contentLabel="ModalStandalone"
        closeTimeoutMS={modalCloseTimeout}
        style={customStyles}
      >
        <div className="modal-content px-3 py-5 font-sans">
          {" "}
          <div className="bg-blue-700 px-4 py-2 shadow-md text-white rounded-lg mb-8 md:text-4xl lg:py-2 lg:px-4 leading-none">
            <p className="font-thin text-base my-1 leading-normal">
              Thanks for your interest!
              <br />
              Before you continue please take your time to read our EULA.
              <br />
              If you agree you can continue the download. Otherwise we can't
              provide you access to our services.
            </p>
          </div>
          <div class="tosContainer">
            <div class="tosContent">
              <h2>
                End-User License Agreement (EULA) of {""}
                <span class="app_name">Arctium</span>
              </h2>
              <b>
                <p>LAST REVISED October 5, 2019</p>
                <p>
                  THIS VERSION OF ARCTIUM END USER LICENSE AGREEMENT WILL BECOME
                  EFFECTIVE ON October 5, 2019.
                </p>
                <p>
                  YOU SHOULD CAREFULLY READ THIS AGREEMENT ("EULA") BEFORE
                  INSTALLING OR USING ARCTIUM PRODUCTS AND SERVICES. IF YOU DO
                  NOT AGREE WITH ALL OF THE TERMS OF THIS AGREEMENT, YOU MAY NOT
                  INSTALL OR OTHERWISE ACCESS THE PLATFORM.
                </p>
              </b>
              <p>
                This End-User License Agreement ("EULA") is a legal agreement
                between you and {""}
                <span class="company_name">Arctium</span>
              </p>
              <p>
                This EULA agreement governs your acquisition and use of our {""}
                <span class="app_name">Arctium</span> Products and Services
                ("Platform") directly from {""}
                <span class="company_name">Arctium</span> or indirectly through{" "}
                a {""}
                <span class="company_name">Arctium</span> authorized reseller or
                distributor (a "Reseller").
              </p>
              <p>
                Please read this EULA agreement carefully before completing the
                installation process and using the {""}
                <span class="app_name">Arctium</span> Platform. It provides a
                license to use the {""}
                <span class="app_name">Arctium</span> Platform and contains
                warranty information and liability disclaimers.
              </p>
              <p>
                If you register for a free trial of the {""}
                <span class="app_name">Arctium</span> Platform, this EULA
                agreement will also govern that trial. By clicking "accept" or
                installing and/or using the {""}
                <span class="app_name">Arctium</span> Platform, you are
                confirming your acceptance of the Platform and agreeing to
                become bound by the terms of this EULA agreement.
              </p>
              <p>
                If you are entering into this EULA agreement on behalf of a
                company or other legal entity, you represent that you have the
                authority to bind such entity and its affiliates to these terms
                and conditions. If you do not have such authority or if you do
                not agree with the terms and conditions of this EULA agreement,
                do not install or use the Platform, and you must not accept this
                EULA agreement.
              </p>
              <p>
                This EULA agreement shall apply only to the Platform supplied by{" "}
                {""}
                <span class="company_name">Arctium</span> herewith regardless of
                whether other Platform is referred to or described herein. The
                terms also apply to any {""}
                <span class="company_name">Arctium</span> updates, supplements,
                Internet-based services, and support services for the Platform,
                unless other terms accompany those items on delivery. If so,
                those terms apply.
              </p>
              <h3>License Grant</h3>
              <p>
                <span class="company_name">Arctium</span> hereby grants you a
                personal, non-transferable, non-exclusive licence to use the{" "}
                {""}
                <span class="app_name">Arctium</span> Platform on your devices
                in accordance with the terms of this EULA agreement.
              </p>
              <p>
                You are permitted to load the {""}
                <span class="app_name">Arctium</span> Platform (for example a
                PC, laptop, mobile or tablet) under your control. You are
                responsible for ensuring your device meets the minimum
                requirements of the {""}
                <span class="app_name">Arctium</span> Platform.
              </p>
              <p>You are not permitted to:</p>
              <ul>
                <li>
                  Edit, alter, modify, adapt, translate or otherwise change the
                  whole or any part of the Platform nor permit the whole or any
                  part of the Platform to be combined with or become
                  incorporated in any other Platform, nor decompile, disassemble
                  or reverse engineer the Platform or attempt to do any such
                  things
                </li>
                <li>
                  Reproduce, copy, distribute, resell or otherwise use the
                  Platform for any purpose
                </li>
                <li>
                  Allow any third party to use the Platform on behalf of or for
                  the benefit of any third party
                </li>
                <li>
                  Use the Platform in any way which breaches any applicable
                  local, national or international law
                </li>
                <li>
                  Use the Platform for any purpose that {""}
                  <span class="company_name">Arctium</span> considers is a
                  breach of this EULA agreement
                </li>
              </ul>
              <h3>Anti-Cheating &amp; Anti-Piracy</h3>
              <p>
                In an effort to combat the efforts of those individuals who are
                willing to violate the EULA, Arctium utilizes an 'anti-cheating'
                utility that runs as part of Arctium products and services. This
                'anti-cheating' utility performs limited scans of:
              </p>
              <ul>
                <li>
                  the Random Access Memory ('RAM') that is occupied by a Arctium
                  product to confirm that the Arctium products program has not
                  been altered or 'hacked' in violation of the EULA;
                </li>
                <li>
                  the Arctium products 'process' to determine if any
                  unauthorized third-party programs or computer code has been
                  attached to the Arctium products process;
                </li>
                <li>
                  the Windows Process List to determine if any confirmed
                  hacking, botting or cheating programs are presently open in
                  violation of the EULA
                </li>
                <li>
                  the Windows Handles list to see which processes have a handle
                  to the Arctium products 'process'. Additional information
                  obtained from RAM and/or disc for the processes that have a
                  handle to the Arctium products 'process' will be used to
                  determine if it is a confirmed hacking, botting or cheating
                  program in violation of the EULA.
                </li>{" "}
              </ul>
              <p>
                The information that is obtained from this activity is
                communicated to Arctium, who uses it solely to determine if the
                computer that a user is using to log on or to use Arctium
                services and prodcuts has any unauthorized third-party programs,
                such as 'hacks', 'bots', or 'cheats' that violate the EULA and
                is not used for any marketing purposes, and will never be sold,
                leased, or otherwise transferred to any third party (except as
                required for judicial action).
              </p>
              <p>
                In the event that Arctium detects an unauthorized third-party
                program, Arctium may in its sole determination:
              </p>
              <ul>
                <li>
                  communicate information back to Arctium, including without
                  limitation your Arctium services account name, details about
                  the unauthorized third party program(s) detected, and the time
                  and date the unauthorized third party program was detected;
                  and/or
                </li>
                <li>
                  exercise any or all of its rights under the EULA, with or
                  without prior notice to you.
                </li>
              </ul>
              <h3>Intellectual Property and Ownership</h3>
              <p>
                <span class="company_name">Arctium</span> shall at all times
                retain ownership of the Platform as originally downloaded by you
                and all subsequent downloads of the Platform by you. The
                Platform (and the copyright, and other intellectual property
                rights of whatever nature in the Platform, including any
                modifications made thereto) are and shall remain the property of{" "}
                {""}
                <span class="company_name">Arctium</span>.
              </p>
              <p>
                <span class="company_name">Arctium</span> reserves the right to
                grant licences to use the Platform to third parties.
              </p>
              <h3>Termination</h3>
              <p>
                This EULA agreement is effective from the date you first use the
                Platform and shall continue until terminated. You may terminate
                it at any time upon written notice to {""}
                <span class="company_name">Arctium</span>.
              </p>{" "}
              <p>
                It will also terminate immediately if you fail to comply with
                any term of this EULA agreement. Upon such termination, the
                licenses granted by this EULA agreement will immediately
                terminate and you agree to stop all access and use of the
                Platform. The provisions that by their nature continue and
                survive will survive any termination of this EULA agreement.
              </p>
              <h3>Disclaimer</h3>
              <p>
                This Platform is provided "AS IS" without warranty of any kind
                including, but not limited to, warranties of merchantability,
                fitness for a particular purpose and non-infringement. In no
                event will Arctium be liable for any direct, indirect,
                incidental, special, exemplary or consequential damages,
                including damages for loss of profits, loss or inaccuracy of
                data, incurred by any person from such person's usage of this
                Platform even if advised of the possibility of such damages.{" "}
              </p>
              <h3>Governing Law</h3>
              <p>
                This EULA agreement, and any dispute arising out of or in
                connection with this EULA agreement, shall be governed by and
                construed in accordance with the laws of {""}
                <span class="country">Germany</span>.
              </p>
            </div>
        </div>
          <div class="modalFooter">
            <DeclineButton
              onClick={closeModal2}
              class="button buttonDecline"
              id="downloadButton"
            >
              Decline
            </DeclineButton>
            <a href="https://arctium.app/standaloneinstall">
              <Button class="button" id="acceptButton">
                Accept &amp; Download
              </Button>
            </a>
          </div>
        </div>
      </Modal>
      <div className="px-2 md:px-8">
        <div className="flex flex-wrap justify-between md:w-full mx-auto">
          <div className="md:w-full lg:w-1/2 lg:pr-4">
            <span className="font-semibold pl-2">We proudly present the</span>
            <div className="flex">
              <h1
                style={{
                  background: `linear-gradient(0deg,#1cb855, #169143)`,
                  WebkitTextFillColor: `transparent`,
                  WebkitBackgroundClip: `text`,
                  marginTop: `-13px`,
                  lineHeight: `normal`,
                }}
                className="md:text-4xl xl:text-6xl mt-0 pr-3 mb-3"
              >
                Arctium App
              </h1>
              <small className="text-sm uppercase font-semibold px-2 py-1 text-white bg-orange-500 inline rounded-full self-start">
                Alpha
              </small>
            </div>

            <div className="bg-blue-700 px-4 py-2 shadow-md text-white rounded-lg mb-8 md:text-4xl lg:py-2 lg:px-4 leading-none">
              <p className="font-thin text-base my-1 leading-normal">
                <span className="font-bold">It's here! Finally!</span> <br />
                The Arctium App has finally arrived and will give you the option to download and use the launcher as integrated part of the Arctium App.
                While we make sure everything works as intended, it's pretty clear that there will be bugs since it's still an early release.
                Make sure to report issues in our #app-feedback Discord Channel.
              </p>
            </div>

            <Button onClick={openModal}>Web Installer</Button>
          </div>
          <div className="md:w-full lg:w-1/2">
            <img
              alt="arctium app"
              style={{ width: `100%` }}
              src={simpleImage}
              className="hidden lg:inline md:mt-3 lg:mt-0 card"
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default App
